import Modal from 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie';

// popup variables
const options = {backdrop: 'static', keyboard: false};
const welcomePopup = new Modal($('#welcome-popup'), options);

// Welcome popup functions
$(document).on('click', '#welcome-popup-confirm', () => {
  const welcomePopupRead = $('#welcome-popup-read').is(':checked');
  if (welcomePopupRead) {
    welcomePopup.hide();
    Cookies.set('welcomePopupRead', welcomePopupRead, { expires: 3 });
  }
});

$(document).on('click', '#welcome-popup-input', () => {
  const welcomePopupRead = $('#welcome-popup-read').is(':checked');
  $('#welcome-popup-confirm').prop('disabled', !welcomePopupRead);
});
