import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1Ijoid2lsZGlzaCIsImEiOiJjbG9ydjliZWUwcTRpMmpvMWs0aXpoeDd0In0.MxDCE5aOqkHKSFKKKDUB6w';

/* eslint-disable no-unused-vars*/
const map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mapbox/streets-v12',
    center: [2.316947931206557, 48.856943016986925],
    zoom: 12,
});

// Create a default Marker and add it to the map.
const marker1 = new mapboxgl.Marker()
.setLngLat([2.316947931206557, 48.856943016986925])
.addTo(map);
